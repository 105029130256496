import {Box, Grid} from '@mui/material';
import cup from '../../assets/cup.png';
import crafting from '../../assets/crafting.png';
import hand from '../../assets/upperHand.png';
const Crafting = () => {
  return (
    <div>
      <Box>
        <Grid container lg={12}>
          <Grid item lg={5} sx={{paddingTop: '15vh'}}>
            {/*<Box>
              <Typography variant='h1'>Crafting</Typography>
            </Box>*/}

            <Box>
              <Box component='img' src={crafting} sx={{width: '100%'}} />

              {/*<Typography variant='h1' sx={{fontFamily: 'Minion Pro'}}>
                Moments of Joy
              </Typography>*/}
            </Box>
          </Grid>
          <Grid item lg={2}>
            <Box
              component='img'
              src={hand}
              sx={{
                width: {xl: '100%', lg: '100%', md: '80%', sm: '80%', xs: '70%'},
                paddingTop: {xl: '52%', lg: '52%', md: '0', sm: '0', xs: '0%'},
                marginLeft: {xl: '-90%', lg: '-90%', md: '-50%', sm: '-50%', xs: '-50%'},
              }}
            />
          </Grid>
          <Grid item lg={5} sx={{paddingTop: {xl: '10vh', lg: '10vh', md: '0', sm: '0', xs: '0'}}}>
            <Box sx={{paddingRight: '0%'}}>
              <Box
                component='img'
                src={cup}
                sx={{
                  width: {xl: '145%', lg: '145%', md: '100%', sm: '90%', xs: '90%'},
                  marginLeft: {xl: '-50%', lg: '-50%', md: '0%', sm: '0%', xs: '0%'},
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Crafting;
