import {Box, Divider, Grid, Typography} from '@mui/material';
import useStyles from './style';
//import logo from '../../assets/logo.png';
//import lounge from '../../assets/1920x1080.png';
//import LocationCard from '../../component/Card/LocationCard';
import newtown from '../../assets/newtown.png';
import colonial from '../../assets/loc-1.png';
import greenpoint from '../../assets/greenpoint.jpg';

import map2 from '../../assets/map-2.png';
import map1 from '../../assets/map-1.png';

import {NavLink} from 'react-router-dom';
import {useState} from 'react';

import egypt from '../../assets/egypt.png';
import LeftRes from '../../component/locres/LeftRes';
import RightRes from '../../component/locres/RightRes';
import egyptIll from '../../assets/egypt-ill.png';

{
  /*const data = [
  {
    id: 1,
    title: 'Greenpoint, BK ',
    desc: '7 BELL SLIP',
    img: lounge,
    to: 'https://maps.app.goo.gl/yt2epSMUZBSqyqVw8',
  },
  {
    id: 2,
    title: 'Charlottesville, VA',
    desc: ' FOOD HALL 946 GRADY AVE',
    img: lounge,
    to: 'https://maps.app.goo.gl/yt2epSMUZBSqyqVw8',
  },
];*/
}

const Location = () => {
  const classes = useStyles();
  const [showN, setShowN] = useState(false);
  const [showC, setShowC] = useState(false);
  const [showG, setShowG] = useState(false);

  const handleHideShowC = () => {
    setShowC(false);
  };
  const handleShowC = () => {
    setShowC(true);
  };
  const handleHideShowN = () => {
    setShowN(false);
  };
  const handleShowN = () => {
    setShowN(true);
  };

  const handleHideShowG = () => {
    setShowG(false);
  };
  const handleShowG = () => {
    setShowG(true);
  };
  return (
    <>
      <div className={classes.Amain}>
        <Box sx={{paddingTop: {xl: '20%', lg: '15%', md: '20%', sm: '30%', xs: '30%'}}}>
          <Grid>
            <Typography
              variant='h1'
              sx={{fontSize: {xl: '3.8rem', lg: '3.8rem', md: '3.8 rem', xs: '2.8rem'}}}>
              Locations
              <br />
            </Typography>
            <Box paddingTop={'75px'}>
              <Divider>
                <Typography
                  variant='h1'
                  sx={{
                    fontSize: {xl: '2.0rem', lg: '2rem', md: '1.8rem', sm: '1.8rem', xs: '1.8rem'},
                    letterSpacing: '0.5rem',
                  }}>
                  Virginia,USA
                </Typography>
              </Divider>
            </Box>
          </Grid>
          <Grid item lg={12} xs={12} sx={{paddingTop: {lg: '5%'}}}>
            <Grid container lg={12} xs={12} sx={{width: '100%'}}>
              <Box
                sx={{
                  display: {xl: 'flex', lg: 'flex', md: 'block', sm: 'block', xs: 'content'},
                  textAlign: 'center',
                  justifyContent: 'center',
                }}>
                {/*Responsive*/}
                <Grid
                  lg={6}
                  sx={{
                    marginLeft: '-100px',
                    display: {xl: 'none', lg: 'none', md: 'flex', sm: 'flex', xs: 'flex'},
                  }}>
                  <LeftRes />
                </Grid>

                <Grid
                  lg={6}
                  sx={{
                    marginLeft: '-100px',
                    display: {xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none'},
                  }}>
                  <NavLink
                    onMouseOver={handleShowC}
                    onMouseLeave={handleHideShowC}
                    to='https://maps.app.goo.gl/gBrV99U9fgzBSxrQ9'
                    style={{
                      textDecoration: showC ? 'none' : 'none',
                    }}>
                    <Box sx={{display: 'flex', paddingTop: '10%', paddingRight: '50px'}}>
                      <Grid lg={6}>
                        <Box
                          component='img'
                          src={map2}
                          sx={{
                            width: {
                              xl: '100%',
                              lg: '110%',
                              md: '70%',
                              sm: '80%',
                              xs: '90%',
                              marginTop: '0px',
                              zIndex: 5,
                              marginLeft: '20%',
                            },
                          }}
                        />
                      </Grid>

                      <Grid lg={6}>
                        <Box sx={{paddingLeft: '20px', marginTop: '5%'}}>
                          <Typography
                            variant='h4'
                            sx={{
                              color: '#e7c978',
                              textAlign: {md: 'left'},
                              marginLeft: {md: '25%'},
                            }}>
                            Colonial Williamsburg
                          </Typography>
                          {showC ? (
                            <>
                              <Typography variant='h6' sx={{color: '#fff'}}>
                                click to view
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography
                                variant='h6'
                                sx={{
                                  color: '#fff',
                                  textAlign: {md: 'left'},
                                  marginLeft: {md: '25%'},
                                }}>
                                Location & Hours
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Box
                          component='img'
                          src={colonial}
                          sx={{
                            width: {xl: '100%', lg: '120%', md: '70%', sm: '80%', xs: '90%'},
                            marginRight: '-50px',
                            zIndex: 10,
                            position: 'relative',
                            marginTop: '-20%',
                          }}
                        />
                      </Grid>
                    </Box>
                  </NavLink>
                </Grid>
                <Grid
                  lg={6}
                  sx={{
                    marginLeft: '-100px',
                    display: {xl: 'none', lg: 'none', md: 'flex', sm: 'flex', xs: 'flex'},
                  }}>
                  <RightRes />
                </Grid>
                <Grid
                  lg={6}
                  sx={{
                    marginLeft: '-100px',
                    display: {xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none'},
                  }}>
                  <NavLink
                    onMouseOver={handleShowN}
                    onMouseLeave={handleHideShowN}
                    to='https://maps.app.goo.gl/VsEiw6Rw8EHb4vWz9'
                    style={{
                      textDecoration: showN ? 'none' : 'none',
                      backdropFilter: showN ? 'blur(3px)' : 'none',
                    }}>
                    <Box sx={{display: 'flex'}}>
                      <Grid lg={6}>
                        <Box
                          component='img'
                          src={newtown}
                          sx={{
                            width: {xl: '100%', lg: '110%', md: '70%', sm: '80%', xs: '90%'},
                            marginRight: '-150px',
                            zIndex: 10,
                            position: 'relative',
                          }}
                        />
                        <Box
                          sx={{
                            paddingLeft: '100px',
                            marginTop: {xl: '80px', lg: '0px', md: '80px'},
                          }}>
                          <Box paddingTop={'75px'} sx={{marginLeft: {md: '50%'}}}>
                            <Typography variant='h4' sx={{color: '#e7c978'}}>
                              New Town Williamsburg
                            </Typography>
                          </Box>
                          <Box sx={{paddingLeft: '50%'}}>
                            {showN ? (
                              <>
                                <Typography variant='h6' sx={{color: '#fff'}}>
                                  click to view
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography
                                  variant='h6'
                                  sx={{color: '#fff', textAlign: {lg: 'center', md: 'left'}}}>
                                  Location & Hours
                                </Typography>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid lg={6}>
                        <Box
                          component='img'
                          src={map1}
                          sx={{
                            width: {
                              xl: '100%',
                              lg: '110%',
                              md: '70%',
                              sm: '80%',
                              xs: '90%',
                              paddingTop: '0px',
                              zIndex: 5,
                              marginLeft: '-50%',
                            },
                          }}
                        />
                      </Grid>
                    </Box>
                  </NavLink>
                </Grid>
              </Box>
              <Grid item lg={12} spacing={12} justifyContent={'space-between'}>
                <Box
                  paddingTop={'75px'}
                  sx={{
                    paddingLeft: {
                      xl: '0',
                      lg: '0',
                      md: '100%',
                      sm: '80%',
                      xs: '30%',
                      paddingBottom: {xl: '', lg: '', md: '', sm: '0%', xs: '20%'},
                    },
                  }}
                  textAlign={'center'}>
                  <Divider>
                    <Typography
                      variant='h1'
                      sx={{
                        fontSize: {
                          xl: '2.0rem',
                          lg: '2rem',
                          md: '1.8rem',
                          sm: '1.8rem',
                          xs: '1.8rem',
                        },
                        letterSpacing: '0.5rem',
                      }}
                      textAlign={'center'}>
                      New York, USA <br />
                    </Typography>
                    <Typography
                      variant='h1'
                      sx={{
                        fontSize: {
                          xl: '1rem',
                          lg: '1.5rem',
                          md: '1rem',
                          sm: '1rem',
                          xs: '1rem',
                        },
                        letterSpacing: '0.2rem',
                      }}>
                      The <b>Original</b> Eleva coffee <br /> AKA | The Bell slip Social <br />
                      Opened in 2020
                    </Typography>
                  </Divider>
                </Box>
              </Grid>
              <Box
                sx={{
                  display: {
                    xl: 'none',
                    lg: 'none',
                    md: 'block',
                    sm: 'block',
                    xs: 'block',
                    paddingTop: '5%',
                  },
                }}
                justifyContent={'center'}
                flexWrap={'wrap'}>
                <Box>
                  <Grid item lg={6} xs={12}>
                    <Box
                      component='img'
                      src={greenpoint}
                      sx={{
                        width: {xl: '100%', lg: '80%', md: '60%', sm: '65%', xs: '80%'},
                        zIndex: 10,
                        border: ' solid 2px gold',
                        borderRadius: '50%',
                      }}
                    />
                  </Grid>
                </Box>
                <Box>
                  {' '}
                  <Grid item xl={6} lg={6}>
                    <NavLink
                      onMouseOver={handleShowG}
                      onMouseLeave={handleHideShowG}
                      to='https://maps.app.goo.gl/PQfirHx9r1Uf7eup7'
                      style={{
                        textDecoration: showG ? 'none' : 'none',
                        backdropFilter: showG ? 'blur(3px)' : 'none',
                      }}>
                      <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box
                          sx={{
                            marginTop: {xl: '80px', lg: '20%', md: '80px'},
                            paddingBottom: '60px',
                          }}>
                          <Box paddingTop={'60px'}>
                            <Typography variant='h4' sx={{color: '#e7c978'}}>
                              Greenpoint Brooklyn{' '}
                            </Typography>
                          </Box>
                          {showG ? (
                            <>
                              <Typography variant='h6' sx={{color: '#fff'}}>
                                click to view
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant='h6' sx={{color: '#fff'}}>
                                Location & Hours{' '}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    </NavLink>
                  </Grid>
                </Box>
              </Box>

              <Grid
                item
                lg={12}
                xs={12}
                sx={{
                  justifyContent: 'space-between',
                  marginLeft: '12%',
                  flexWrap: 'wrap',
                  display: {xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none'},
                  paddingY: '5%',
                }}>
                <NavLink
                  onMouseOver={handleShowG}
                  onMouseLeave={handleHideShowG}
                  to='https://maps.app.goo.gl/PQfirHx9r1Uf7eup7'
                  style={{
                    textDecoration: showG ? 'none' : 'none',
                    backdropFilter: showG ? 'blur(3px)' : 'none',
                  }}>
                  <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
                    <Grid item lg={6} xs={12}>
                      <Box
                        component='img'
                        src={greenpoint}
                        sx={{
                          width: {xl: '120%', lg: '190%', md: '70%', sm: '100%', xs: '100%'},
                          zIndex: 10,
                          border: ' solid 2px gold',
                          borderRadius: '50%',
                          display: {xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none'},
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <Box
                        sx={{
                          marginLeft: '50%',
                          marginTop: {xl: '80px', lg: '20%', md: '80px'},
                        }}>
                        <Box paddingTop={'60px'}>
                          <Typography variant='h4' sx={{color: '#e7c978'}}>
                            Greenpoint Brooklyn{' '}
                          </Typography>
                        </Box>
                        {showG ? (
                          <>
                            <Typography variant='h6' sx={{color: '#fff'}}>
                              click to view
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant='h6' sx={{color: '#fff'}}>
                              Location & Hours{' '}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Box>
                </NavLink>
              </Grid>

              <Grid item lg={12} xs={12}>
                <Divider>
                  <Typography
                    variant='h1'
                    sx={{
                      fontSize: {
                        xl: '2.0rem',
                        lg: '2rem',
                        md: '1.8rem',
                        sm: '1.8rem',
                        xs: '1.8rem',
                      },
                      letterSpacing: '0.5rem',
                    }}>
                    International
                  </Typography>
                </Divider>{' '}
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                sx={{
                  backgroundImage: `url(${egyptIll})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right center',
                  objectFit: 'scale-down',
                }}>
                <Grid
                  lg={6}
                  sx={{
                    paddingBottom: '0%',
                    display: {xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex'},
                    marginLeft: {xl: '30%', lg: '15%', md: '-10%', sm: '-10%', xs: '-10%'},
                  }}>
                  <Box
                    component='img'
                    src={egypt}
                    sx={{
                      width: {
                        xl: '100%',
                        lg: '100%',
                        md: '70%',
                        sm: '80%',
                        xs: '70%',
                        zIndex: 5,
                      },
                    }}
                  />
                </Grid>
                <Grid lg={12} xs={12}>
                  <Box
                    sx={{
                      paddingLeft: {xl: '40%', lg: '40%', md: '40%', sm: '60%', xs: '50%'},
                      paddingBottom: '20px',
                    }}>
                    <Typography variant='h4' sx={{color: '#e7c978'}}>
                      Cairo,Egypt
                    </Typography>
                    <>
                      <Typography variant='h6' sx={{color: '#fff'}}>
                        Opening Fall 2024 !
                      </Typography>
                    </>
                  </Box>
                </Grid>
              </Grid>
              <Grid lg={6}>
                <Box
                  sx={{
                    paddingLeft: {xl: '25%', lg: '10%', md: '10%', sm: '10%', xs: '10%'},
                    paddingRight: {xl: '20%', lg: '20%', md: '10%', sm: '10%', xs: '10%'},
                    paddingY: '15%',
                  }}>
                  <Typography
                    variant='h3'
                    sx={{
                      textAlign: {xl: 'left', lg: 'left', md: 'center', sm: 'center', xs: 'center'},
                      color: '#e7c978',
                      fontSize: {
                        xl: '3rem',
                        lg: '3rem',
                        md: '2.5rem',
                        sm: '2rem',
                        xs: '2rem',
                      },
                    }}>
                    Stay tuned for more details!
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Location;
