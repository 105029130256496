import AboutSection from '../../component/About/About';
import hand from '../../assets/hand.png';
import UHand from '../../assets/upperHand.png';
import ECup from '../../assets/eleva-cup.png';
import Drops from '../../assets/drops.png';
import HILL from '../../assets/HILL.png';
import Head from '../../assets/Head.png';
import Head2 from '../../assets/head2.png';
import Text2 from '../../assets/text2.png';
//import HILL2 from '../../assets/HILL2.png';
//import lounge1 from '../../assets/lounge1.png';
//import lounge2 from '../../assets/lounge2.png';
import useStyles from './style';
import {Box, Grid, Typography} from '@mui/material';
import {NavLink} from 'react-router-dom';
import {useState} from 'react';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const Franchise = () => {
  const [showArrow, setShowArrow] = useState(false);
  const classes = useStyles();

  const handleShow = () => {
    setShowArrow(true);
  };

  const handleHide = () => {
    setShowArrow(false);
  };
  return (
    <>
      <div className={classes.Amain}>
        <AboutSection
          Licon={UHand}
          Ricon={hand}
          png={Head2}
          RFull
          LFull
          Title='Exceptional Craft Coffee in a 
Haven of Elegance and Comfort at Eleva Coffee Lounge.'
        />{' '}
        <Box>
          <Grid container lg={12}>
            <Grid item lg={6}>
              <Box
                component='img'
                src={ECup}
                sx={{width: {xl: '100%', lg: '100%', md: '95%', sm: '95%', xs: '95%'}}}
              />
            </Grid>
            <Grid item lg={12}>
              <Box
                component='img'
                src={Head}
                sx={{width: {xl: '50%', lg: '50%', md: '80%', sm: '80%', xs: '80%'}}}
              />
            </Grid>
            <Grid item lg={6}></Grid>
            <Grid item lg={6}>
              <Box
                component='img'
                src={Drops}
                sx={{width: {xl: '100%', lg: '100%', md: '100%', sm: '95%', xs: '95%'}}}
              />
            </Grid>
            <Grid item lg={8} sm={12} xs={12}>
              <Box
                component='img'
                src={HILL}
                sx={{width: {xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '100%'}}}
              />
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                marginTop: {xl: '-45%', lg: '-45%', md: '-80%', sm: '-80%', xs: '-80%'},
                marginLeft: {xl: '-30px', lg: '-30px', md: '-30%', sm: '-30%', xs: '-25%'},
              }}>
              <Box
                component='img'
                src={Text2}
                sx={{width: {xl: '80%', lg: '80%', md: '60%', sm: '60%', xs: '60%'}}}
              />
              <Box>
                <>
                  <NavLink
                    to='eleva-lounge-locations'
                    onMouseOver={handleShow}
                    onMouseLeave={handleHide}
                    style={{textDecoration: 'none'}}>
                    <Box sx={{display: 'flex', paddingLeft: '35%'}}>
                      <Box>
                        <Typography
                          variant='h1'
                          sx={{
                            fontSize: {
                              xl: '',
                              lg: '3rem',
                              md: '2.5rem',
                              sm: '2.8rem',
                              xs: '1.9rem',
                            },
                          }}>
                          {' '}
                          Our Locations
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          paddingTop: {xl: '20px', lg: '20px', md: '15px', sm: '18px', xs: '5px'},
                          paddingLeft: '20px',
                        }}>
                        {showArrow ? (
                          <>
                            {' '}
                            <ArrowCircleRightIcon sx={{fontSize: '35px', color: '#fff'}} />
                          </>
                        ) : (
                          <>
                            <ArrowCircleRightIcon sx={{fontSize: '35px', color: 'GrayText'}} />
                          </>
                        )}{' '}
                      </Box>
                    </Box>
                  </NavLink>
                </>
              </Box>
            </Grid>
            {/*<Grid item lg={10}>
              <Box
                component='img'
                src={Drops}
                sx={{width: {xl: '70%', lg: '70%', md: '100%', sm: '95%', xs: '95%'}}}
              />
            </Grid>
            <Grid item lg={8} sm={10} xs={10}>
              <Box
                component='img'
                src={HILL2}
                sx={{
                  width: {xl: '100%', lg: '100%', md: '110%', sm: '110%', xs: '110%'},
                  transform: {
                    xl: 'rotate(-45deg)',
                    lg: 'rotate(-45deg)',
                    md: 'rotate(-45deg)',
                    sm: 'rotate(-40deg)',
                    xs: 'rotate(-50deg)',
                  },
                }}
              />
            </Grid>
            <Grid item lg={7} sx={{marginTop: '-30%', marginLeft: '7%'}}>
              <Box component='img' src={lounge1} sx={{width: '85%'}} />
            </Grid>
            <Grid
              item
              lg={12}
              sx={{
                marginTop: '0',
                textAlign: 'right',
                paddingRight: {xl: '50px', lg: '50px', md: '50px', sm: '20px', xs: '20px'},
              }}>
              <Box
                component='img'
                src={Text2}
                sx={{width: {xl: '40%', lg: '40%', md: '100%', sm: '100%', xs: '100%'}}}
              />
            </Grid>
            <Grid item lg={6}>
              <Box
                component='img'
                src={lounge2}
                sx={{width: {xl: '100%', lg: '100%', md: '100%', sm: '95%', xs: '95%'}}}
              />
            </Grid>*/}
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Franchise;
