import {Box, Typography} from '@mui/material';

const TextSection = props => {
  const {title, text} = props;
  return (
    <>
      <Box>
        <Typography variant='h2' sx={{textAlign: 'left', color: '#fff', lineHeight: '4rem'}}>
          {title}
        </Typography>
      </Box>
      <Box sx={{paddingRight: {xl: '', lg: '60%', md: '', sm: '', xs: '30px'}, paddingTop: '20px'}}>
        <Typography
          variant='h4'
          sx={{
            textAlign: 'left',
            color: '#fff',
            fontWeight: '300',
            fontSize: {xl: '2rem', lg: '2rem', md: '1.8rem', sm: '1.8rem', xs: '1.8rem'},
          }}>
          {text}
        </Typography>
      </Box>
    </>
  );
};

export default TextSection;
