import {makeStyles} from '@mui/styles';
export default makeStyles(() => ({
  main: {
    marginLeft: 'auto',
    marginRight: 'auto',
    alignContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '10vh',
  },
  bg_media: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    alignContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  hero_container: {
    width: '80%',
    height: 'auto',
    gap: '32px',
    alignContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '20px',
    display: 'blocK',
    marginLeft: '150px',
    marginRight: '150px',
    marginTop: '150px',
    marginBottom: '150px',
    paddingTop: '10vh',
    paddingBottom: '10vh',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  hero__container__Header_section: {
    paddingTop: '0px',
    paddingBottom: '120px',
  },
  hero__container__Header__btn_section: {
    paddingBottom: '60px',
  },
}));
