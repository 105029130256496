import {Box, Grid, Paper, Typography} from '@mui/material';
import {NavLink} from 'react-router-dom';
import astore from '../../assets/app-store.png';

const AppStore = () => {
  return (
    <div>
      {' '}
      <Grid
        sx={{
          paddingRight: {xl: '50px', lg: '0px', md: '0%', sm: '0px', xs: '0px'},
          paddingLeft: {xl: '0', lg: '0', md: '0%', sm: '0px', xs: '0px'},
          paddingTop: {xl: '15px', lg: '15px', md: '10px', sm: '10px', xs: '10px'},
        }}>
        <NavLink style={{textDecoration: 'none'}} to='https://www.apple.com/eg-ar/app-store/'>
          <Paper>
            <Grid container lg={12}>
              <Grid item lg={5} sm={5} xs={5}>
                <Box sx={{paddingTop: '8px', paddingBottom: '5px'}}>
                  <Box
                    component='img'
                    src={astore}
                    sx={{width: {xl: '50%', lg: '50%', md: '35%', sm: '35%', xs: '35%'}}}
                  />
                </Box>
              </Grid>
              <Grid item lg={7} sx={{paddingY: '2px', marginLeft: '-15px'}}>
                <Box sx={{textAlign: 'left', paddingTop: '5px'}}>
                  <Grid>
                    <Typography
                      variant='h7'
                      sx={{
                        fontSize: {
                          xl: '1.4rem',
                          lg: '1.4rem',
                          md: '1.4rem',
                          sm: '1.4rem',
                          xs: '1.4rem',
                        },
                      }}>
                      Download From
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: {
                          xl: '1.6rem',
                          lg: '1.6rem',
                          md: '1.6rem',
                          sm: '1.6rem',
                          xs: '1.6rem',
                        },
                      }}>
                      App Store
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </NavLink>
      </Grid>
    </div>
  );
};

export default AppStore;
