import {Route, Routes} from 'react-router-dom';
import {Contact, Franchise, Landing, Location, Menu, NotFound, EApp} from '../Pages';
import {Footer, Header} from '../container';

import {Box} from '@mui/material';

const App = () => {
  return (
    <>
      <main>
        <Box sx={{xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none'}}>
          <Header />
        </Box>

        <Routes>
          <Route exact path='/landing' element={<Landing />} />
          <Route path='/eleva-lounge-menu' element={<Menu />} />
          {/*<Route path='/eleva-lounge-locations' element={<Location2 />} />*/}
          <Route path='/eleva-lounge-locations' element={<Location />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/eleva-coffee-app' element={<EApp />} />
          <Route exact path='/' element={<Franchise />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </main>
    </>
  );
};

export default App;
