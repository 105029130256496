import {createTheme} from '@mui/material';

const theme = createTheme({
  color: {
    primary: {
      main: '#000',
      darkGreen: '#2b3836',
      darkGray: '#353535',
      darkGolden: '#be974f',
      lightGolden: '#e7c978',
      contrastText: '#fff',
    },
    secondary: {main: '#000'},
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'Gallient',
    ].join(','),
    h1: {
      fontWeight: 300,
      fontSize: '5rem',
      lineHeight: 1.67,
      letterSpacing: '-0.01562em',
      fontFamily: 'Gallient',
      color: '#e7c978',
    },
    h2: {
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontWeight: 400,
      fontSize: '2.063rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
      fontFamily: 'Avenir',
      fontStyle: 'oblique',
      color: '#fff',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
    inherit: {
      fontWeight: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
    },
    description: {
      fontWeight: 400,
      fontSize: '33px',
    },
  },
  spacing: {
    xs: '.25rem', // Used for general spacing
    sm: '.5rem', // Used for a small amount of spacing
    md: '1rem', // Used for medium amounts of spacing
    lg: '1.5rem', // Used for large amounts of spacing
    xl: '2rem', // Used for extra large amounts of spacing
    gutter: '3rem', // Used to separate rows in grid layouts
  },

  breakpoints: {
    values: {
      xs: 0, // phone - screen width  => 576px
      sm: 576, // tablet - screen width  => 576px
      // and screen width  <  768px
      md: 768,
      lg: 992, // desktop - screen width  => 992px
      xl: '1024px', // laptop - screen width  => 1024px
    },
  },
  zIndex: {
    appBar: -1,
    drawer: 1001,
    modal: 1002,
    snackbar: 1003,
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
});

export default theme;
