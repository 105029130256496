import {Box, Grid, Modal, Typography} from '@mui/material';
import useStyles from './style';
//import data from '../../conestant/data';
//import cmenu from '../../assets/cup-menu.png';
//import Logo from '../../assets/logo.png';
import cup from '../../assets/cup-menu.png';
import Ecup from '../../assets/empty-cup.png';
import Efood from '../../assets/food1.png';
import food from '../../assets/food2.png';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
//import {Hero} from '../../container';
//import hero from '../../assets/bg.jpg';
//import logo from '../../assets/logo.png';
// Core viewer
import drinks from '../../assets/Drinks.png';
import food2 from '../../assets/food.png';

const Menu = () => {
  const classes = useStyles();
  const [showD, setShowD] = useState(false);
  const [showO, setShowO] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [openF, setOpenF] = useState(false);
  const handleOpenD = () => setOpenD(true);
  const handleCloseD = () => setOpenD(false);
  const handleOpenF = () => setOpenF(true);
  const handleCloseF = () => setOpenF(false);

  const handleOverD = () => {
    setShowD(true);
  };

  const handleLeaveD = () => {
    setShowD(false);
  };
  const handleOverO = () => {
    setShowO(true);
  };

  const handleLeaveO = () => {
    setShowO(false);
  };

  return (
    <>
      <div className={classes.main}>
        {/*<Box>
          <Hero bg={hero} img={logo} btn_title='Grab your Order' to='/contact-us' />
        </Box>*/}
        <Box
          sx={{
            textAlign: 'center',
          }}>
          <Grid container lg={12} paddingBottom={5}>
            <Grid
              item
              lg={12}
              sx={{
                textAlign: 'center',
                paddingX: {xl: '0%', lg: '0%', md: '10%', sm: '5%', xs: '5%'},
                paddingBottom: '5%',
              }}>
              {/*<Box component='img' src={Logo} alt='eleva-coffee-lounge-logo' sx={{width: '50%'}} />*/}
              <Box
                sx={{
                  paddingTop: {lg: '11%', md: '25%', sm: '25%', xs: '20%'},
                  textAlign: 'center',
                }}>
                <Typography
                  variant='h1'
                  sx={{
                    textAlign: 'center',
                    fontSize: {md: '150px', sm: '"45px', xs: '45px'},
                  }}>
                  {' '}
                  Our Lounge Menus
                </Typography>
                <Box sx={{paddingX: {lg: '20%'}}}>
                  <Typography
                    variant='h4'
                    sx={{
                      textAlign: 'center',
                      fontSize: {xl: '50px', lg: '30px', xs: '17px'},
                    }}>
                    {' '}
                    Our menus were created to delight and deliver expertly crafted classics and new
                    exciting options so you can enjoy old favorites and discover new ones.
                    <br />
                    <br />
                    We encourge locations to compliment our menus with a local touch, so you will
                    find more exciting options when you visit our lounges today.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} sm={12} xs={12} spacing={2} gap={2} paddingTop={5}>
              <Box sx={{display: 'flex'}}>
                <Grid
                  container
                  lg={12}
                  sx={{
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    paddingTop: '0%',
                  }}>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    onMouseOver={handleOverD}
                    onMouseLeave={handleLeaveD}
                    sx={{paddingX: {xl: '10%', lg: '0%', md: '25%', sm: '0%', xs: '0%'}}}>
                    {!showD ? (
                      <>
                        <Box
                          component='img'
                          src={Ecup}
                          alt='eleva-coffee-lounge-logo'
                          sx={{
                            width: {xl: '85%', lg: '30%', md: '95%', sm: '40%', xs: '40%'},
                            padding: '20px',
                            borderRadius: '25px',
                            border: '1px solid #be974f',
                            transition: 'ease 1s',
                          }}
                        />
                        <Box>
                          <Typography
                            variant='h1'
                            sx={{
                              fontSize: {
                                xl: '2rem',
                                lg: '2rem',
                                md: '1.8rem',
                                sm: '1.7rem',
                                xs: '1.7rem',
                              },
                              letterSpacing: '0.4rem',
                            }}>
                            Drinks
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <NavLink
                        onClick={handleOpenD}
                        onClose={handleCloseD}
                        style={{textDecoration: 'none'}}>
                        <Box
                          component='img'
                          src={cup}
                          alt='eleva-coffee-lounge-logo'
                          sx={{
                            width: {xl: '85%', lg: '30%', md: '95%', sm: '40%', xs: '40%'},
                            '&:hover': {
                              border: '3px solid #be974f',
                              padding: '20px',
                              borderRadius: '25px',
                              transition: 'ease-out 0.5s',
                            },
                          }}
                        />
                        <Box sx={{paddingTop: '20px'}}>
                          <Typography
                            variant='h1'
                            sx={{
                              fontSize: {
                                xl: '2rem',
                                lg: '2rem',
                                md: '1.8rem',
                                sm: '1.5rem',
                                xs: '1.5rem',
                              },
                              letterSpacing: '0.4rem',
                            }}>
                            View Menu
                          </Typography>
                        </Box>
                      </NavLink>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    onMouseOver={handleOverO}
                    onMouseLeave={handleLeaveO}
                    sx={{
                      paddingX: {xl: '10%', lg: '0%', md: '25%', sm: '0%', xs: '0%'},
                      marginTop: '20px',
                    }}>
                    {!showO ? (
                      <>
                        <Box
                          component='img'
                          src={Efood}
                          alt='eleva-coffee-lounge-logo'
                          sx={{
                            width: {xl: '100%', lg: '30%', md: '100%', sm: '40%', xs: '40%'},
                            padding: '10px',
                            borderRadius: '25px',
                            border: '1px solid #be974f',
                            transition: 'ease 1s',
                          }}
                        />
                        <Box>
                          <Typography
                            variant='h1'
                            sx={{
                              fontSize: {
                                xl: '2rem',
                                lg: '2rem',
                                md: '1.8rem',
                                sm: '1.7rem',
                                xs: '1.7rem',
                              },
                              letterSpacing: '0.4rem',
                            }}>
                            Food
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <NavLink
                        onClick={handleOpenF}
                        onClose={handleCloseF}
                        style={{textDecoration: 'none', margin: '0'}}>
                        <Box
                          component='img'
                          src={food}
                          alt='eleva-coffee-lounge-logo'
                          sx={{
                            width: {xl: '100%', lg: '30%', md: '100%', sm: '40%', xs: '40%'},
                            '&:hover': {
                              border: '3px solid #be974f',
                              padding: '20px',
                              borderRadius: '25px',
                              transition: 'ease-out 0.5s',
                            },
                          }}
                        />
                        <Box sx={{paddingTop: '20px'}}>
                          <Typography
                            variant='h1'
                            sx={{
                              fontSize: {
                                xl: '2rem',
                                lg: '2rem',
                                md: '1.8rem',
                                sm: '1.5rem',
                                xs: '1.5rem',
                              },
                              letterSpacing: '0.4rem',
                            }}>
                            View Menu
                          </Typography>
                        </Box>
                      </NavLink>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/*<Box
          sx={{
            paddingTop: {xl: '30vh', lg: '', md: '', sm: '', xs: '5vh'},
            paddingLeft: {xl: '120px', lg: '40px', md: '10px', sm: '20px', xs: '20px'},
            paddingRight: {xl: '120px', lg: '40px', md: '10px', sm: '20px', xs: '20px'},
            paddingBottom: {xl: '120px', lg: '120px', md: '60px', sm: '20px', xs: '0px'},
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            justifyItems: 'center',
            display: 'flex',
            flexDirection: {xl: 'row', lg: 'row', md: 'column', sm: 'column', xs: 'column'},
            gap: '20px',
          }}>
          {data.map((item, index) => (
            <>
              <Grid
                container
                lg={12}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: '#000',
                  color: '#e7c978',
                  textAlign: 'center',
                  borderRadius: '18px',
                  paddingY: '20px',
                  marginY: '20px',
                  display: 'flex',
                }}
                key={index}>
                <Grid item lg={12} xs={12}>
                  <Box
                    sx={{
                      paddingLeft: {
                        xl: '50px',
                        lg: '50px',
                        md: '50px',
                        sm: '100px',
                        xs: '30px',
                      },
                      paddingRight: {
                        xl: '50px',
                        lg: '50px',
                        md: '50px',
                        sm: '100px',
                        xs: '30px',
                      },
                    }}>
                    <Box sx={{borderBlockEnd: 'solid 2px #e7c978', display: 'flex'}}>
                      <Box
                        component='img'
                        src={cmenu}
                        sx={{
                          width: {xl: '15%', lg: '15%', md: '15%', sm: '15%', xs: '15%'},
                          paddingRight: '20px',
                          paddingBottom: '10px',
                          marginLeft: '-10px',
                        }}
                      />

                      <Typography variant='h5' sx={{textAlign: 'left', paddingBottom: '0px'}}>
                        {item.Title}
                      </Typography>
                    </Box>
                    <Box sx={{display: 'flex', paddingTop: '20px'}}>
                      <Grid lg={8} xs={8}>
                        <Box sx={{textAlign: 'left'}}>
                          <Box key={index}>
                            {item.items.map(iName => {
                              return (
                                <div key={iName.id}>
                                  <Typography> {iName.Name}</Typography>
                                </div>
                              );
                            })}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid lg={4} xs={4}>
                        <Box sx={{textAlign: 'left'}}>
                          {item.items.map((price, index) => {
                            return (
                              <div key={index}>
                                <Typography> {price.Price} $</Typography>
                              </div>
                            );
                          })}
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          ))}
        </Box>
       */}{' '}
        <Modal
          open={openD}
          onClose={handleCloseD}
          onClick={handleCloseD}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',

              justifyContent: 'center',
            }}>
            <Box
              sx={{
                textAlign: 'center',
                paddingTop: {xl: '0', lg: '0', md: '0', sm: '20%', xs: '20%'},
              }}>
              <Box
                component='img'
                src={drinks}
                sx={{
                  width: {xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '100%'},
                }}
              />
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openF}
          onClose={handleCloseF}
          onClick={handleCloseF}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',

              justifyContent: 'center',
            }}>
            <Box
              sx={{
                textAlign: 'center',
                paddingTop: {xl: '0', lg: '0', md: '0', sm: '20%', xs: '20%'},
              }}>
              <Box
                component='img'
                src={food2}
                sx={{
                  width: {xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '100%'},
                }}
              />
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Menu;
