import {Box, Grid} from '@mui/material';
import useStyles from './style';
import mount from '../../assets/mount.png';
//import ContactForm from '../../component/Forms/ContactForm';
import FC from '../../component/Forms/FC';
import img from '../../assets/eleva-cup.png';
const Contact = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.main}>
        <Box
          sx={{
            paddingTop: '20vh',
            paddingLeft: {xl: '120px', lg: '120px', md: '120px', sm: '100px', xs: '30px'},
            paddingRight: {xl: '120px', lg: '120px', md: '120px', sm: '100px', xs: '20px'},
            paddingBottom: '120px',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            justifyItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Grid
            container
            lg={12}
            sx={{
              color: '#e7c978',
              textAlign: 'center',
              borderRadius: '18px',
              marginTop: '-60px',
            }}>
            {' '}
            <Grid
              item
              lg={6}
              xs={12}
              sx={{display: {xl: 'flex', lg: 'flex', md: 'none', sx: 'none', xs: 'none'}}}>
              <Box>
                {' '}
                <Box component='img' src={img} sx={{width: '100%'}} />
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <FC />
            </Grid>
            <Grid
              item
              lg={6}
              sx={{display: {xl: 'none', lg: 'none', md: 'flex', sm: 'flex', xs: 'flex'}}}>
              <Box>
                <Box component='img' src={img} sx={{width: {md: '100%', sm: '100%', xs: '100%'}}} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid>
            <Box
              component='img'
              src={mount}
              sx={{
                width: {xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '100%'},
              }}
            />
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Contact;
