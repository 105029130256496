import {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import {Alert, Box, Button, Paper, Stack, TextField, Typography} from '@mui/material';

function App() {
  const form = useRef();
  const [isSend, setIsSend] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSendEmail = async e => {
    e.preventDefault();

    emailjs
      .sendForm('service_eleva', 'template_xnb77ol', form.current, '-vug0U_6_775nV3Ml')
      .then(result => {
        console.log(result.text);
        setIsSend(true);
        <Stack sx={{width: '100%'}} spacing={2}>
          <Alert onClose={() => {}}>The Message has been Submitted.</Alert>
        </Stack>;
        setTimeout(() => {
          setIsSend(false);
        }, 2500);
      })
      .catch(error => {
        console.log(error.text);
      });
    resetFields();
  };
  const resetFields = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div style={{paddingTop: '0px'}}>
      <Paper
        elevation={7}
        sx={{
          marginTop: '110px',
          padding: {xl: '50px', lg: '50px', md: '35px', sm: '25px', xs: '25px'},
          marginLeft: {xl: '20%', lg: '20%', md: '0%', sm: '0%', xs: '0'},
          marginRight: {xl: '20%', lg: '20%', md: '0%', sm: '0%', xs: '0'},
        }}>
        <Box>
          <Typography
            variant='h1'
            sx={{fontSize: {xl: '3.8rem', lg: '3.8rem', md: '3.8rem', sm: '3.8rem', xs: '3.8rem'}}}>
            Contact Us
          </Typography>
        </Box>
        <form ref={form} onSubmit={handleSendEmail}>
          <div>
            <TextField
              type='text'
              name='user_name'
              value={name}
              onChange={e => setName(e.target.value)}
              label='Name'
              sx={{width: '90%', paddingBottom: '10px'}}
            />
          </div>
          <div>
            <TextField
              type='email'
              name='user_email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              label='Email'
              sx={{width: '90%', paddingBottom: '10px'}}
            />
          </div>
          <div>
            <TextField
              type='text'
              multiline
              rows={4}
              name='message'
              value={message}
              onChange={e => setMessage(e.target.value)}
              label='Message'
              sx={{width: '90%', paddingBottom: '10px'}}
            />
          </div>
          <Button
            type='submit'
            variant='contained'
            sx={{
              height: '50px',
              width: '50%',
              backgroundColor: '#be974f',
              '&:hover': {
                backgroundColor: '#353535',
              },
            }}>
            Send
          </Button>
          {isSend && (
            <div>
              <Stack sx={{width: '100%'}} spacing={2}>
                <Alert onClose={() => {}}>Message Sent ...</Alert>
              </Stack>
            </div>
          )}
        </form>
      </Paper>
    </div>
  );
}

export default App;
