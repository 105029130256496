import {Box, Grid, Typography} from '@mui/material';
import app from '../../assets/app.png';
import mount from '../../assets/mount.png';
import PlayStore from '../btn/playStore';
import AppStore from '../btn/appStore';
const App = () => {
  return (
    <div>
      {' '}
      <Box>
        <Grid
          container
          lg={12}
          spacing={2}
          sx={{
            marginBottom: '-70vh',
            paddingTop: {xl: '20vh', lg: '20vh', md: '20vh', sm: '20vh', xs: '0'},
            zIndex: 5,
            position: 'inherit',
          }}>
          <Grid
            item
            lg={4}
            sx={{
              zIndex: 5,
              position: 'inherit',
              paddingLeft: {xl: '10vh', lg: '10vh', md: '0', sm: '0', xs: '0'},
            }}>
            <Box sx={{paddingTop: {xl: '50vh', lg: '50vh', md: '0vh', sm: '0vh', xs: '10vh'}}}>
              <Typography
                variant='h2'
                sx={{
                  color: '#fff',
                  fontWeight: 400,
                  paddingLeft: {xl: '45px', lg: '45px', md: '0', sm: '0', xs: '20px'},
                  fontSize: {xl: '2.8rem', lg: '2.8rem', md: '2.8rem', sm: '2.8rem', xs: '2.3rem'},
                  textAlign: {xl: 'left', lg: 'left', md: 'center', sm: 'center', xs: 'center'},
                }}>
                Download our app
              </Typography>
            </Box>
            <Box
              sx={{
                paddingLeft: {xl: '50px', lg: '50px', md: '50px', sm: '80px', xs: '20px'},
                xs: '50px',
                paddingRight: {xl: '0', lg: '0', md: '0', sm: '80px', xs: '20px'},
                paddingTop: {xl: '0', lg: '0', md: '20px', sm: '20px', xs: '20px'},
              }}>
              <Typography
                variant='h4'
                sx={{
                  textAlign: {xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center'},
                  fontSize: {xl: '1.8rem', lg: '1.8rem', md: '1.8rem', sm: '1.8rem', xs: '1.8rem'},
                }}>
                will be available on App Store and Google Play soon.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} sx={{zIndex: 5, position: 'inherit', paddingTop: '20px'}}>
            <Box
              component='img'
              src={app}
              sx={{
                width: {xl: '45%', lg: '45%', md: '60%', sm: '60%', xs: '60%'},
                height: '100%',
                marginLeft: {xl: '-30vh', lg: '-30vh', md: '10vh', sm: '5vh', xs: '0'},
              }}
            />
          </Grid>
          <Box
            sx={{
              display: {xl: 'none', lg: 'none', md: 'block', sm: 'block', xs: 'block'},
              paddingTop: {xl: '50vh', lg: '50vh', md: '5vh', sm: '5vh', xs: '5vh'},
              marginX: {md: '100px', sm: '80px', xs: '35px'},
            }}>
            <PlayStore />
            <AppStore />{' '}
          </Box>
          <Grid
            item
            lg={2}
            sx={{
              zIndex: 5,
              position: 'inherit',
              marginLeft: {xl: '-20vh', lg: '-20vh', md: '0', sm: '0', xs: '0'},
              paddingTop: {xl: '50vh', lg: '50vh', md: '10vh', sm: '50vh', xs: '10vh'},
            }}>
            <Box sx={{display: {xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none'}}}>
              <Box sx={{paddingY: '-50px'}}>
                <PlayStore />
              </Box>
              <Box>
                <AppStore />{' '}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{marginTop: {xl: '0%', lg: '0%', md: '80vh', sm: '50vh', xs: '80vh'}}}>
          <Grid lg={12} xs={12}>
            <Box
              component='img'
              src={mount}
              sx={{
                width: {xl: '100%', lg: '1920px', md: '100%', sm: '100%', xs: '100%'},
                height: '100%',
              }}
            />
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default App;
