import outline from '../../assets/outline.png';
import {Box, Grid, Typography} from '@mui/material';
import './Responsive.css';

const About = props => {
  const {Licon, Ricon, Title, Desc, LFull, RFull, Mountain, png} = props;

  return (
    <div>
      {' '}
      <Grid container lg={12} xl={12}>
        <Grid item lg={3} sx={{paddingTop: '5vh', height: '20vh'}}>
          <Box
            component='img'
            src={Licon}
            sx={{
              width: LFull
                ? {xl: '100%', lg: '100%', md: '60%', sm: '60%', xs: '80%'}
                : {xl: '50%', lg: '80%', md: '40%', sm: '50%', xs: '40%'},
              marginLeft: {xl: '0', lg: '0', md: '-65%', sm: '-80%', xs: '-70%'},
            }}
          />
        </Grid>
        <Grid
          item
          lg={6}
          sx={{paddingTop: {xl: '15vh', lg: '', md: '20vh', sm: '10vh', xs: '10vh'}}}>
          {png ? (
            <Box
              component='img'
              src={png}
              sx={{
                width: png ? {xl: '100%', lg: '100%', md: '95%', sm: '95%', xs: '95%'} : '50%',
              }}
            />
          ) : (
            <>
              <Box sx={{marginTop: {xl: '0px', lg: '0px', md: '0px', sm: '20px', xs: '-50px'}}}>
                <Box sx={{paddingX: {xl: '0', lg: '0px', md: '80px', sm: '100px', xs: '35px'}}}>
                  <Typography
                    className='header'
                    variant='h1'
                    sx={{
                      fontSize: {
                        xl: '6.3rem',
                        lg: '6.3rem',
                        md: '10rem',
                        sm: '6.3rem',
                        xs: '3.4rem',
                      },
                    }}>
                    {Title}
                  </Typography>
                </Box>
                <Box sx={{paddingX: {xl: '40px', lg: '0px', md: '40px', sm: '40px', xs: '40px'}}}>
                  <Typography
                    variant='h4'
                    sx={{
                      fontSize: {
                        xl: '2.7rem',
                        lg: '2.7rem',
                        md: '2.5rem',
                        sm: '1.8rem',
                        xs: '1.5rem',
                      },
                    }}>
                    {Desc}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Grid>{' '}
        <Grid
          item
          lg={3}
          sx={{
            paddingTop: {xl: '25vh', lg: '25vh', md: '5vh', sm: '10vh', xs: '5vh'},
          }}>
          <Box
            component='img'
            src={Ricon}
            sx={{
              marginRight: {xl: '', lg: '0%', md: '', sm: '-40%', xs: '-30%'},
              width: RFull ? {xl: '100%', lg: '100%', md: '60%', sm: '60%', xs: '60%'} : '50%',
            }}
          />
        </Grid>
      </Grid>
      {Mountain ? (
        <Box sx={{paddingTop: '50px'}}>
          <Grid lg={12}>
            <Box
              component='img'
              src={outline}
              sx={{
                width: {xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '100%'},
                height: {xl: '450px', lg: '450px'},
              }}
            />
          </Grid>
        </Box>
      ) : (
        ''
      )}
    </div>
  );
};

export default About;
