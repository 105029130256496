import {Box, Button, Typography} from '@mui/material';

import useStyle from './style';
import {NavLink} from 'react-router-dom';

import './Responsive.css';

const Hero = props => {
  const {bg, title, btn_title, img, to} = props;
  const classes = useStyle();

  return (
    <>
      <Box
        className={classes.main}
        sx={{
          backgroundImage: `url(${bg})`,
          maxWidth: '100%',
          height: {xl: '100vh', lg: '100vh', md: '100vh', sm: '100vh', xs: '100vh'},
        }}>
        <Box className={classes.bg_media}>
          <Box className={classes.hero_container}>
            <Box className={classes.hero__container__Header_section}>
              <Box
                component='img'
                src={img}
                sx={{width: {xl: '40%', lg: '50%', md: '70%', sm: '80%', xs: '90%'}}}
              />
              <Typography variant='h1'>{title}</Typography>
            </Box>
            <Box className={classes.hero__container__Header__btn_section}>
              <NavLink to={to}>
                <Button
                  variant='contained'
                  sx={{
                    height: '55px',
                    width: {xl: '15%', lg: '30%', md: '40%', sm: '40%', xs: '60%'},
                    backgroundColor: '#be974f',
                    '&:hover': {
                      backgroundColor: '#353535',
                    },
                  }}>
                  {btn_title}
                </Button>
              </NavLink>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
