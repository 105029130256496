import {makeStyles} from '@mui/styles';

export default makeStyles(() => ({
  LMain: {
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#2b3836',
  },
}));
