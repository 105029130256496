import {Box, Button, Container, Grid, Typography} from '@mui/material';
import useStyle from './Style';
import Logo from '../../assets/logo.png';
import {NavLink} from 'react-router-dom';
import ResHeader from './ResHeader';

const pages = [
  {
    title: 'Home',
    link: '/',
  },
  {title: 'Eleva Coffee', link: 'https://elevacoffee.com'},
  {title: 'Menu', link: '/eleva-lounge-menu'},
  {title: 'Locations', link: '/eleva-lounge-locations'},
  {
    /*title: 'Franchise', link: '/franchise-with-eleva'*/
  },
  {
    /*{title: 'Locations', link: '/eleva-lounge-locations'}*/
    /*{title: 'Eleva-App', link: '/eleva-coffee-app'},
  {title: 'Contact', link: '/contact-us'},*/
  },
];

const Header = () => {
  const classes = useStyle();

  return (
    <>
      <Container className={classes.HeaderMain} maxWidth='xl'>
        <Box>
          <Grid
            container
            xl={12}
            sx={{display: {xl: 'none', lg: 'none', md: 'flex', sm: 'flex', xs: 'flex'}}}>
            <ResHeader />
          </Grid>
          <Grid
            container
            xl={12}
            sx={{display: {xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none'}}}>
            <Grid item lg={6}>
              <Box>
                <NavLink className={classes.Nav} to='/'>
                  <Box
                    className={classes.logo}
                    component='img'
                    src={Logo}
                    alt='eleva-coffee-lounge-logo'
                  />
                </NavLink>
              </Box>
            </Grid>
            <Grid item lg={6} className={classes.HeaderContent}>
              <Box sx={{flexGrow: 1, display: {xs: 'none', lg: 'flex'}}}>
                {pages.map(page => (
                  <Button
                    key={page}
                    sx={{
                      my: 2,
                      color: 'white',
                      display: 'block',
                      paddingX: '20px',
                    }}>
                    <NavLink className={classes.Nav} to={page.link} active>
                      <Box>
                        <Typography variant='h6' sx={{textTransform: 'capitalize'}}>
                          {page.title}
                        </Typography>
                      </Box>
                    </NavLink>
                  </Button>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Header;
