import {makeStyles} from '@mui/styles';
import bg from '../../assets/line-03.png';

export default makeStyles(() => ({
  Amain: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: '1',
    alignContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#2b3836',
    paddingTop: '5vh',
  },
  bg: {
    backgroundImage: `url(${bg})`,
    marginLeft: '0px',
    width: '300px',
  },
}));
