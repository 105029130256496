import {Box, Grid, Typography} from '@mui/material';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import map1 from '../../assets/map-1.png';

import newtown from '../../assets/newtown.png';

const RightRes = () => {
  const [showM, setShowM] = useState(false);

  const handleHideShowM = () => {
    setShowM(false);
  };
  const handleShowM = () => {
    setShowM(true);
  };
  return (
    <div>
      <NavLink
        onMouseOver={handleShowM}
        onMouseLeave={handleHideShowM}
        to='https://maps.app.goo.gl/gBrV99U9fgzBSxrQ9'
        style={{
          textDecoration: showM ? 'none' : 'none',
          backdropFilter: showM ? 'blur(3px)' : 'none',
        }}>
        <Box sx={{display: 'flex', paddingTop: '0%'}}>
          <Grid lg={6}>
            <Box sx={{paddingLeft: {md: '40%', sm: '40%', xs: '40%'}, marginTop: '0%'}}>
              <Typography variant='h4' sx={{color: '#e7c978'}}>
                Newtown Williamsburg
              </Typography>
              {showM ? (
                <>
                  <Typography variant='h6' sx={{color: '#fff'}}>
                    Show Me
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant='h6' sx={{color: '#fff'}}>
                    Opening Summer 2024!
                  </Typography>
                </>
              )}
            </Box>
            <Box
              component='img'
              src={newtown}
              sx={{
                width: {xl: '100%', lg: '120%', md: '100%', sm: '100%', xs: '100%'},
                marginLeft: '150px',
                zIndex: 10,
                position: 'relative',
                marginTop: '-0%',
              }}
            />
          </Grid>
          <Grid lg={6}>
            <Box
              component='img'
              src={map1}
              sx={{
                width: {
                  xl: '100%',
                  lg: '110%',
                  md: '100%',
                  sm: '100%',
                  xs: '100%',
                  marginTop: '50px',
                  zIndex: 5,
                },
              }}
            />
          </Grid>
        </Box>
      </NavLink>
    </div>
  );
};

export default RightRes;
