import {Grid, Box, Typography} from '@mui/material';
import useStyles from './style';
import logo from '../../assets/logo-coffee.png';
import app1 from '../../assets/app1.png';
import app2 from '../../assets/app2.png';
import hand from '../../assets/hand.png';

import mount from '../../assets/mount.png';
import bg from '../../assets/line-03.png';
import PlayStore from '../../component/btn/playStore';
import AppStore from '../../component/btn/appStore';
import TextSection from '../../component/text_section/TextSection';

const EApp = () => {
  const classess = useStyles();
  return (
    <>
      <div className={classess.Amain}>
        <Grid container lg={12} spacing={2}>
          <Grid
            item
            lg={6}
            xs={12}
            sx={{paddingTop: {xl: '5%', lg: '7%', md: '10%', sm: '15%', xs: '15%'}}}>
            <Grid>
              {' '}
              <Box component='img' src={logo} sx={{width: '55%'}} />
            </Grid>
            <Grid>
              <Box
                sx={{
                  paddingLeft: {xl: '15%', lg: '15%', md: '5%', sm: '5%', xs: '5%'},
                  paddingRight: {xl: '15%', lg: '15%', md: '5%', sm: '5%', xs: '5%'},
                  paddingY: '25px',
                }}>
                <Typography
                  variant='h4'
                  textAlign='left'
                  sx={{
                    color: '#fff',
                    textAlign: {xl: 'left', lg: 'left', md: 'center', sm: 'center', xs: 'center'},
                    fontSize: {
                      xl: '2.8rem',
                      lg: '2.8rem',
                      md: '2.8rem',
                      sm: '2.5rem',
                      xs: '1.8rem',
                    },
                  }}>
                  Where your Coffee Cravings
                  <br /> Meet Convenience
                </Typography>
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  backgroundColor: '#be974f',
                  marginX: {xl: '15%', lg: '12%', md: '15%', sm: '15%', xs: '5%'},
                  border: 'solid 2px transpaernt',
                  borderRadius: '10px',
                  padding: '10px',
                }}>
                <Grid container lg={12} sx={{}}>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Grid>
                      <Box
                        sx={{
                          paddingTop: {xl: '15px', lg: '15px', md: '15px', sm: '15px', xs: '15px'},
                        }}>
                        <Typography
                          variant='h5'
                          sx={{
                            textTransform: 'uppercase',
                            color: '#fff',
                            textAlign: {
                              xl: 'left',
                              lg: 'left',
                              md: 'center',
                              sm: 'center',
                              xs: 'center',
                            },
                            paddingLeft: {xl: '5%', lg: '5%', md: '10%', sm: '10%', xs: '10%'},
                            paddingRight: {xl: '5%', lg: '5%', md: '10%', sm: '10%', xs: '10%'},
                            fontSize: {
                              xl: '1.9rem',
                              lg: '1.9rem',
                              md: '1.8rem',
                              sm: '1.8rem',
                              xs: '1.8rem',
                            },
                          }}>
                          {' '}
                          Download Our App{' '}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid>
                      <Box
                        sx={{
                          paddingTop: {xl: '15px', lg: '15px', md: '15px', sm: '15px', xs: '15px'},
                          paddingLeft: {xl: '5%', lg: '5%', md: '10%', sm: '10%', xs: '10%'},
                          paddingRight: {xl: '5%', lg: '5%', md: '10%', sm: '10%', xs: '10%'},
                        }}>
                        <Typography
                          variant='h6'
                          sx={{
                            color: '#fff',
                            textAlign: {
                              xl: 'left',
                              lg: 'left',
                              md: 'center',
                              sm: 'center',
                              xs: 'center',
                            },
                            lineHeight: '2rem',
                            fontSize: {
                              xl: '1.6rem',
                              lg: '1.6rem',
                              md: '1.6rem',
                              sm: '1.6rem',
                              xs: '1.5rem',
                            },
                          }}>
                          {' '}
                          Will be Available On App Store and Google Play Store
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        paddingLeft: {md: '15px', sm: '15px', xs: '15px'},
                        paddingRight: {md: '15px', sm: '15px', xs: '15px'},
                        paddingY: {lg: '20px', md: '20px', sm: '20px', xs: '20px'},
                      }}>
                      <PlayStore />
                      <AppStore />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid>
              <Box>
                <Typography
                  variant='h5'
                  sx={{
                    color: '#fff',
                    textAlign: {xl: 'left', lg: 'left', md: 'center', sm: 'center', xs: 'center'},
                    paddingLeft: {xl: '130px', lg: '130px', md: '0px', sm: '0px', xs: '0px'},
                    paddingTop: '25px',
                    fontStyle: 'italic',
                  }}>
                  Savor every moment
                  <br />
                  effortlessly with our App
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item lg={6}>
            <Grid>
              {' '}
              <Box
                component='img'
                src={app1}
                sx={{width: {xl: '50%', lg: '50%', md: '70%', sm: '70%', xs: '70%'}}}
              />
            </Grid>
            <Grid sx={{marginLeft: '50%', marginTop: '-15%'}}>
              <Box component='img' src={hand} sx={{width: '100%'}} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container lg={12} sx={{backgroundImage: `url(${bg})`}}>
          <Grid item lg={12}>
            <Box sx={{paddingLeft: '15%', paddingTop: '10%'}}>
              <TextSection
                title='Mobile Ordering'
                text='Users can place and pay for thier orders through the app, allowing them to skip the line and pick up thier drinks and food directly from the counter.'
              />
            </Box>
          </Grid>
          <Grid item lg={12} sx={{paddingTop: '0%'}}>
            <Box
              sx={{
                paddingRight: {xl: '20%', lg: '20%', md: '10%', sm: '5%', xs: '5%'},
                paddingTop: '0%',
                textAlign: 'right',
              }}>
              <Box
                component='img'
                src={app2}
                sx={{width: {xl: '40%', lg: '50%', md: '90%', sm: '90%', xs: '90%'}}}
              />
            </Box>
          </Grid>
          <Grid item lg={2}></Grid>
          <Grid item lg={10}>
            <Box sx={{paddingLeft: '20%', paddingTop: '10%'}}>
              <TextSection
                title='Rewards Program'
                text='The app often includes a rewards Program
where users earn stars for each purchase.
Accumulated stars can be redeemed for
free drinks, food, and other rewards.'
              />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box
              sx={{paddingLeft: '10%', paddingTop: '10%', textAlign: 'left', paddingRight: '50px'}}>
              <Box
                component='img'
                src={app2}
                sx={{width: {xl: '40%', lg: '50%', md: '90%', sm: '90%', xs: '100%'}}}
              />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box
              sx={{
                paddingLeft: {xl: '15%', lg: '20%', md: '15%', sm: '15', xs: '15%'},
                paddingTop: '0%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'right',
              }}>
              <TextSection
                title='Store Locator'
                text='Users can find nearby Eleva locations using the app, including information about store hours, amenities, and whether mobile ordering is available.'
              />
            </Box>
          </Grid>
        </Grid>
        <Grid lg={12}>
          <Box
            component='img'
            src={mount}
            sx={{
              width: {xl: '100%', lg: '1920px', md: '100%', sm: '100%', xs: '100%'},
            }}
          />
        </Grid>
      </div>
    </>
  );
};

export default EApp;
