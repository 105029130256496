import {makeStyles} from '@mui/styles';

export default makeStyles(() => ({
  Amain: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: '1',
    alignContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#2b3836',
  },
}));
