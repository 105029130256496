import {Box, Typography} from '@mui/material';
import Social from '../../component/Social';
import logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <>
      <Box gap={2} sx={{backgroundColor: '#2b3836', padding: '20px'}} justifyContent={'center'}>
        <Box>
          <Social />
        </Box>
        <Box textAlign={'center'}>
          <Typography
            variant='h7'
            sx={{
              color: '#fff',
            }}>
            2024 &copy; All rights reserved
          </Typography>
        </Box>
        <Box textAlign={'center'}>
          <Box
            component={'img'}
            src={logo}
            sx={{
              width: {xl: '5%', lg: '5%', md: '10%', sm: '15%', xs: '15%'},
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
