import {About, Hero} from '../../container';
import useStyle from './styles';
import hero from '../../assets/bg.jpg';
import logo from '../../assets/logo.png';
import './Responsive.css';
const Landing = () => {
  const classes = useStyle();

  return (
    <>
      <div className={classes.LMain}>
        <Hero bg={hero} img={logo} title='' btn_title='Grab your Order' to='/contact-us' />
        <About />
      </div>
    </>
  );
};

export default Landing;
