import AboutSection from '../../component/About/About';
import useStyle from './style';
import hand from '../../assets/hand.png';
import bean from '../../assets/bean.png';
import App from '../../component/App/App';
import Crafting from '../../component/Crafting/Crafting';
import './Responsive.css';

const About = () => {
  const classes = useStyle();
  return (
    <>
      <div className={classes.Amain}>
        <AboutSection
          Licon={bean}
          Ricon={hand}
          RFull
          Mountain
          Title='Introducing Craft Coffee'
          Desc='Eleva coffee lounge invites you to savor an unparalleled journey of exceptional craft coffee, promising elegance from bean sourcing to the moment of enjoyment in a haven of comfort'
        />
        <Crafting />
        <App />
      </div>
    </>
  );
};

export default About;
