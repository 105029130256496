import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {Box} from '@mui/material';
import {NavLink} from 'react-router-dom';

const data = [
  {
    id: 1,
    icon: <FacebookIcon fontSize='large' />,
    to: 'https://www.facebook.com/elevacoffee/',
  },
  {
    id: 2,
    icon: <InstagramIcon fontSize='large' />,
    to: 'https://www.instagram.com/elevacoffee/',
  },
];
const Social = () => {
  return (
    <Box display={'flex'} gap={2} justifyContent={'center'}>
      {data.map(item => (
        <Box key={item.id}>
          <NavLink
            to={item.to}
            style={{
              textDecoration: 'none',
              color: '#fff',
              '&:hover': {
                color: 'goldenrod',
              },
            }}>
            {item.icon}
          </NavLink>
        </Box>
      ))}
    </Box>
  );
};

export default Social;
