import {makeStyles} from '@mui/styles';
import hero from '../../assets/outline.png';
import bean from '../../assets/bean.png';
import hand from '../../assets/hand.png';

export default makeStyles(() => ({
  Amain: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: '1',
    alignContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  bg_media: {
    width: '100%',
    height: '100vh',
    maxHeight: '100%',
    backgroundImage: `url(${hero})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    alignContent: 'normal',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0%',
  },
  hero_container: {
    width: '100%',
    gap: '32px',
    alignContent: 'right',
    alignItems: 'right',
    justifyItems: 'right',
    justifyContent: 'right',
    textAlign: 'right',
    padding: '20px',
    border: 'solid 2px #000',
    display: 'blocK',
  },
  hero__container__Header_section: {
    paddingTop: '200px',
    paddingBottom: '120px',
  },
  hero__container__Header__btn_section: {
    paddingTop: '75 px',
    paddingBottom: '60px',
  },
  BG_img_L: {
    width: '100%',
    backgroundImage: `url(${bean})`,
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    alignContent: 'normal',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0%',
  },
  BG_img_R: {
    width: '1900px',
    height: '100vh',
    backgroundImage: `url(${hand})`,
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    alignContent: 'normal',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20%',
  },
}));
