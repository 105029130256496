import {makeStyles} from '@mui/styles';

export default makeStyles(() => ({
  main: {
    alignContent: 'center',
    alignItems: 'center',
    padding: 0,
    marginLeft: '-10px',
    marginRight: '-10px',
    marginTop: '-10px',
    marginBottom: '-10px',
    backgroundColor: '#2b3836',
  },
}));
